import React, { useEffect } from 'react'
import SEOTools from '../components/SEOTools'
import { Col, Container, Row } from 'react-bootstrap'
import { graphql } from 'gatsby'
import HeroUnit from '../components/HeroUnit'
import { getSrc } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import EcoPreRegForm from '../components/Forms/EcoPreRegForm'
import useSmoothScrollTo from '../hooks/useSmoothScrollTo'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const StartEcoPreregistrationPage = ({ data }) => {
    const handleScrollToForm = useSmoothScrollTo('form-section', { offset: -100 })

    // Scroll to form on page load
    useEffect(() => {
        handleScrollToForm()
    }, [handleScrollToForm])

    return (
        <Layout>
            <SEOTools
                title='Register to ecoPayz'
                description="Let's start our journey together with ecoPayz and Siru!"
                image={getSrc(data.heroBg.childImageSharp)}
            />
            <HeroUnit backgroundImage={data.heroBg} className='darker-background-overlay'>
                <Row className='d-flex align-items-center justify-content-center text-center text-white my-5'>
                    <Col md='8'>
                        <h1>Register to ecoPayz</h1>
                    </Col>
                </Row>
            </HeroUnit>

            <section className='py-5'>
                <Container>
                    <EcoPreRegForm />
                </Container>
            </section>
        </Layout>
    )
}

/**
 * Page query can be dynamic with variables. Can only be run on a page level.
 */
export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "products/sirupay/sirupay_hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`

export default StartEcoPreregistrationPage
